import React, { Component } from "react";
import "./fonts";

export default class LeagueMark extends Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          height: 60,
          width: 130,
          borderRadius: 30,
          transformOrigin: "top right",
          backgroundColor: "rgb(228, 244, 229)",
          outline: "3px solid rgb(173, 173, 173)",
          transformOrigin: "right top",
          transform: "scale(" + (window.innerHeight / 60) * 0.07 + ")"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            height: "55%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Iceland-Regular, Icelands",
            fontSize: 30,
            fontWeight: "bold",
            color: "rgb(157, 157, 157)"
          }}
        >
          {"FUN"}
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            height: "65%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Iceland-Regular, Icelands",
            fontSize: 30,
            fontWeight: "bold",
            color: "rgb(157, 157, 157)"
          }}
        >
          {"LEAGUE"}
        </div>
      </div>
    );
  }
}
