import Crypto from "./CryptoUtils";

export default class BackendHandler {
  constructor(identity) {
    this.baseUrl =
      "https://2za44rgqof.execute-api.us-east-1.amazonaws.com/production/";
    this.identity = identity;
  }

  listPuzzles(cb) {
    // TODO: sigmature
    let subdir = "list-puzzles";
    let data = {};
    let signed = Crypto.sign(data, this.identity.keys);
    //console.log(JSON.stringify(signed));
    fetch(this.baseUrl + subdir, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(signed)
    })
      .then(response => response.json())
      .then(data => {
        // [{"id":"c6447d27-9837-4a33-800a-11d5b1c2f6dc","total_tries":"2","word_length":8},{"id":"58353bd1-1015-4a48-b3be-42cbd9c9ac4d","total_tries":"0","word_length":7}]
        console.log(data);
        if (data.length == undefined) {
          console.log("Error");
          return;
        }
        cb(data);
      })
      .catch(error => console.log(error));
  }

  addWord(word, cb) {
    // TODO: sigmature
    let subdir = "add-word";
    let data = { word: word };
    let signed = Crypto.sign(data, this.identity.keys);
    console.log(JSON.stringify(signed));
    fetch(this.baseUrl + subdir, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(signed)
    })
      .then(response => response.json())
      .then(data => {
        // {"word":"problem","creator_id":"1edd5148-eb4a-4ed5-a2ee-c82ab1281933","id":"3f69dc9e-317f-4d32-ab78-6a932b1e5e66","created_at":"2022-02-09T23:24:21.592Z"}
        console.log(data);
        if (data.id == undefined) {
          console.log("error");
          cb();
        }
        cb(data);
      })
      .catch(error => console.log(error));
  }

  tryWord(word, puzzleId, cb) {
    let subdir = "try-word";
    let data = {
      puzzle_id: puzzleId,
      guess: word.toLowerCase()
    };
    let signed = Crypto.sign(data, this.identity.keys);
    console.log(JSON.stringify(signed));
    fetch(this.baseUrl + subdir, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(signed)
    })
      .then(response => response.json())
      .then(data => {
        //[0,1,0,0,0,0,0,0]
        // "true" -> puzzle solved
        console.log(data);
        if (data === "true" || data === true) {
          cb(new Array(word.length).fill(2));
          return;
        }
        if (data.length == undefined || data.length != word.length) {
          console.log("Bad try");
          // TODO: error codes?
          cb([]);
        }
        cb(data);
      })
      .catch(error => console.log(error));
  }
}
