import React, { Component } from "react";
import Landing from "./Landing";
import Create from "./Create";
import Solve from "./Solve";
import Footer from "./Footer";
import Identity from "./Identity";
import BackendHandler from "./BackendHandler";
import LeagueMark from "./LeagueMark";

export default class Main extends Component {
  constructor(props) {
    super(props);
    this.identity = new Identity();
    this.be = new BackendHandler(this.identity);
  }

  componentDidMount() {
    window.onresize = () => this.resizeWindow();
    this.resizeWindow();
  }

  state = {
    page: "landing",
    windowScale: 1
  };

  resizeWindow() {
    let minWidth = 1100;
    let minHeight = 850;
    let scaleY =
      window.innerHeight >= minHeight ? 1 : window.innerHeight / minHeight;
    let scaleX =
      window.innerWidth >= minWidth ? 1 : window.innerWidth / minWidth;
    let scale = 0.9 * Math.min(scaleX, scaleY);
    this.setState({ windowScale: scale });
  }

  onResize() {
    // minWidth: 1100
    // minHeight: 850
  }

  onCreate() {
    this.setState({ page: "create" });
  }

  onSolve() {
    this.setState({ page: "solve" });
  }

  onLanding() {
    this.setState({ page: "landing" });
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "100%",
          width: "100%",
          backgroundColor: "rgb(222, 222, 222)",
          overflow: "hidden"
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
            transition: "transform ease-in-out 0.2s",
            transform:
              "translateX(" +
              (this.state.page == "landing"
                ? "0"
                : this.state.page == "solve"
                ? "-100%"
                : "100%") +
              ")"
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              height: "100%",
              width: "100%"
            }}
          >
            <Landing
              identity={this.identity}
              onSolve={this.onSolve.bind(this)}
              onCreate={this.onCreate.bind(this)}
              be={this.be}
              windowScale={this.state.windowScale}
            />
          </div>
          <div
            style={{
              position: "absolute",
              left: "100%",
              top: 0,
              height: "100%",
              width: "100%"
            }}
          >
            <Solve
              active={this.state.page}
              identity={this.identity}
              onLanding={this.onLanding.bind(this)}
              be={this.be}
              windowScale={this.state.windowScale}
            />
          </div>
          <div
            style={{
              position: "absolute",
              left: "-100%",
              top: 0,
              height: "100%",
              width: "100%"
            }}
          >
            <Create
              active={this.state.page}
              identity={this.identity}
              onLanding={this.onLanding.bind(this)}
              be={this.be}
              windowScale={this.state.windowScale}
            />
          </div>
        </div>
        <Footer identity={this.identity} />
        <LeagueMark windowScale={this.state.windowScale} />
      </div>
    );
  }
}
