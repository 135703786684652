import React, { Component } from "react";
import BigButton from "./BigButton";
import SmallButton from "./SmallButton";
import Crypto from "./CryptoUtils";

const letters = "abcdefghijklmnopqrstuvwxyz";

class Record extends Component {
  state = {
    button: ""
  };
  render() {
    return (
      <div
        key={this.props.id}
        style={{
          position: "relative",
          height: 40,
          width: "100%",
          fontFamily: "DenkOne-Regular, Denk One",
          fontSize: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "rgb(46, 46, 46)"
        }}
      >
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            left: -120,
            fontFamily: "DenkOne-Regular, Denk One",
            fontSize: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(46, 46, 46)"
          }}
        >
          {":"}
        </div>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "50%",
            right: "calc(50% + 130px)",
            fontFamily: "DenkOne-Regular, Denk One",
            fontSize: 20,
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            color: "rgb(46, 46, 46)"
          }}
        >
          {this.props.record.word}
        </div>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "50%",
            left: "calc(50% - 110px)",
            fontFamily: "DenkOne-Regular, Denk One",
            fontSize: 20,
            display: "flex",
            //justifyContent: "center",
            alignItems: "center",
            color: "rgb(46, 46, 46)"
          }}
        >
          {this.props.id}
        </div>
        <div
          style={{
            position: "absolute",
            height: "70%",
            width: 70,
            top: "15%",
            right: 100,
            fontSize: 13,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(46, 46, 46)",
            outline: "1px solid rgb(107, 107, 107)",
            borderRadius: 3,
            cursor: "grab",
            userSelect: "none",
            backgroundColor:
              this.state.button == "pressed"
                ? "rgb(236, 236, 236)"
                : this.state.button == "hovered"
                ? "rgb(242, 242, 242)"
                : "rgb(227, 227, 227)"
          }}
          onClick={this.props.onCancel}
          onMouseEnter={() => {
            this.setState({ button: "hovered" });
          }}
          onMouseLeave={() => {
            this.setState({ button: "" });
          }}
          onMouseDown={() => {
            this.setState({ button: "pressed" });
          }}
          onMouseUp={() => {
            this.setState({ button: "hovered" });
          }}
        >
          {"CANCEL"}
        </div>
      </div>
    );
  }
}

class Letter extends Component {
  render() {
    return (
      <div
        style={{
          position: "relative",
          width: 60,
          height: 60,
          outline: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "black",
          backgroundColor: this.props.letter
            ? "rgb(255, 255, 255)"
            : "rgb(208, 208, 208)",
          fontFamily: "PasseroOne-Regular, Passero One",
          fontSize: 35,
          transition:
            "transform ease-in-out 0.15s, background-color ease-in-out 0.15s",
          transform: "scale(" + (this.props.letter ? 1.1 : 1) + ")"
        }}
      >
        {this.props.letter}
      </div>
    );
  }
}

export default class Create extends Component {
  componentDidMount() {
    window.addEventListener("keydown", event => {
      if (this.props.active != "create") return;
      if (event.keyCode == 8 || event.keyCode == 46) {
        this.deleteLetter();
      } else if (event.keyCode >= 65 && event.keyCode <= 90) {
        this.addLetter(letters[event.keyCode - 65]);
      }
    });
  }

  deleteLetter() {
    if (this.state.word.length > 0)
      this.setState({ word: this.state.word.slice(0, -1) });
  }

  addLetter(letter) {
    if (this.state.word.length < 10)
      this.setState({ word: this.state.word + letter });
  }

  state = {
    word: "crypto",
    myWords: {}
  };

  onSubmit() {
    // this is to upload puzzles to back end
    this.props.be.addWord(this.state.word, response => {
      if (response.id == undefined) return;
      // TODO: use "address" instead of actual id
      this.state.myWords[response.id] = {
        word: this.state.word
      };
      // TODO: notify solver to update puzzle list?
      //this.props.onPuzzlesUpdated(dict);
      this.setState({ word: "" });
    });
  }

  // this is for local version only
  getPuzzle(numLetters) {
    for (let key in this.state.myWords) {
      if (this.state.myWords[key].word.length == numLetters)
        return this.state.myWords[key].word;
    }
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "100%",
          width: "100%"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            width: "100%"
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: 650,
              width: 1000,
              //backgroundColor: "rgb(154, 250, 215)",
              display: "flex",
              justifyContent: "center",
              transform:
                "translate(" +
                (window.innerWidth / 2 - 1000 / 2) +
                "px, " +
                (window.innerHeight / 2 - 650 / 2) +
                "px) scale(" +
                (window.innerHeight / 650) * 0.8 +
                ")"
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 20,
                height: 100,
                width: 800,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "DenkOne-Regular, Denk One",
                fontSize: 23,
                color: "rgb(73, 73, 73)"
                //overflowY: "auto"
              }}
            >
              {"What word do you have in mind?"}
            </div>
            <div
              style={{
                position: "absolute",
                top: 100,
                height: 100,
                width: 800,
                //backgroundColor: "rgb(166, 64, 232)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                transform: "scale(0.9)"
              }}
            >
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(id => {
                return (
                  <Letter
                    key={id}
                    letter={
                      id > this.state.word.length
                        ? undefined
                        : this.state.word[id]
                    }
                  />
                );
              })}
            </div>
            <div
              style={{
                position: "absolute",
                top: 280,
                height: 100,
                width: 800,
                //backgroundColor: "rgb(166, 64, 232)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "DenkOne-Regular, Denk One",
                fontSize: 30,
                color: "rgb(46, 46, 46)"
              }}
            >
              {"My words"}
            </div>
            <div
              style={{
                position: "absolute",
                top: 370,
                left: 0,
                height: 500,
                width: 1000
                //overflowY: "auto"
              }}
            >
              {Object.keys(this.state.myWords).length > 0 ? (
                Object.keys(this.state.myWords).map((key, id) => {
                  return (
                    <Record
                      key={key}
                      id={key}
                      record={this.state.myWords[key]}
                      onCancel={() => {
                        delete this.state.myWords[key];
                        this.setState({});
                      }}
                    />
                  );
                })
              ) : (
                <div
                  style={{
                    position: "relative",
                    height: 40,
                    width: "100%",
                    fontFamily: "DenkOne-Regular, Denk One",
                    fontSize: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "rgb(130, 130, 130)"
                  }}
                >
                  {"Currently have no saved words."}
                </div>
              )}
            </div>
            <div
              style={{
                position: "absolute",
                top: 220,
                height: 100,
                width: 200,
                display: "flex",
                justifyContent: "center"
              }}
            >
              <SmallButton text="SUBMIT" onClick={this.onSubmit.bind(this)} />
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 20,
            left: 20,
            height: 70,
            width: 200,
            transformOrigin: "left top",
            transform: "scale(" + (window.innerHeight / 70) * 0.07 + ")"
          }}
        >
          <BigButton text="BACK" onClick={this.props.onLanding} />
        </div>
      </div>
    );
  }
}
