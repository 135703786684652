import React, { Component } from "react";
import "./fonts";
import BigButton from "./BigButton";

class WinnerList extends Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 140,
          left: 0,
          height: 800,
          width: "100%",
          fontFamily: "DenkOne-Regular, Denk One",
          fontSize: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "rgb(103, 103, 103)"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: 70,
            width: "100%",
            fontFamily: "DenkOne-Regular, Denk One",
            fontSize: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(103, 103, 103)"
          }}
        >
          {this.props.text}
        </div>
        <div
          style={{
            position: "absolute",
            top: 60,
            left: 0,
            height: 720,
            width: "100%",
            fontSize: 20,
            color: "rgb(103, 103, 103)",
            overflowY: "auto"
          }}
        >
          {Object.keys(this.props.list).map((key, id) => {
            return (
              <div
                key={id}
                style={{
                  position: "relative",
                  height: 40,
                  width: "100%",
                  fontFamily: "DenkOne-Regular, Denk One",
                  fontSize: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "rgb(46, 46, 46)"
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    fontFamily: "DenkOne-Regular, Denk One",
                    fontSize: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "rgb(46, 46, 46)"
                  }}
                >
                  {":"}
                </div>
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "50%",
                    right: "50%",
                    paddingRight: 10,
                    fontFamily: "DenkOne-Regular, Denk One",
                    fontSize: 20,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    color: "rgb(46, 46, 46)"
                  }}
                >
                  {key}
                </div>
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "50%",
                    left: "50%",
                    paddingLeft: 10,
                    fontFamily: "DenkOne-Regular, Denk One",
                    fontSize: 20,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "rgb(46, 46, 46)"
                  }}
                >
                  {this.props.list[key]}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default class Landing extends Component {
  state = {
    topCreators: { aasdkljhasdf: 102039480, bb: 90, casdjfhasdfc: 80239048 },
    topSolvers: { a: 10293840, baskjdfhkasjdf: 90, ccc: 8239480 }
  };
  onCreate() {
    this.props.onCreate();
  }
  onSolve() {
    this.props.onSolve();
  }
  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "100%",
          width: "100%",
          overflow: "hidden"
        }}
      >
        <div
          style={{
            position: "absolute",
            width: 1000,
            height: 800,
            left: 0,
            top: 0,
            //backgroundColor: "rgb(203, 203, 203)",
            transform:
              "translate(" +
              (window.innerWidth / 2 - 1000 / 2) +
              "px, " +
              (window.innerHeight / 2 - 800 / 2) +
              "px) scale(" +
              window.innerHeight / 800 +
              ")"
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "45%",
              height: "100%",
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
              //backgroundColor: "red"
            }}
          >
            <div
              style={{
                position: "relative",
                top: 10,
                width: 600,
                height: 600,
                display: "flex",
                justifyContent: "center",
                backgroundColor: "rgb(210, 210, 210)",
                borderRadius: 10
                //alignItems: "center"
              }}
            >
              <WinnerList text="TOP CREATORS" list={this.state.topCreators} />
              <div
                style={{
                  position: "relative",
                  top: 50,
                  height: 70,
                  width: 200
                }}
              >
                <BigButton text="CREATE" onClick={this.onCreate.bind(this)} />
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              width: "45%",
              height: "100%",
              top: 0,
              right: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
              //backgroundColor: "red"
            }}
          >
            <div
              style={{
                position: "relative",
                top: 10,
                width: 600,
                height: 600,
                display: "flex",
                justifyContent: "center",
                backgroundColor: "rgb(210, 210, 210)",
                borderRadius: 10
                //alignItems: "center"
              }}
            >
              <WinnerList text="TOP SOLVERS" list={this.state.topSolvers} />
              <div
                style={{
                  position: "relative",
                  top: 50,
                  height: 70,
                  width: 200
                }}
              >
                <BigButton text="SOLVE" onClick={this.onSolve.bind(this)} />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 10,
            left: 0,
            height: 100,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "PasseroOne-Regular, Passero One",
            fontSize: 60,
            //backgroundColor: "rgb(212, 212, 212)",
            transformOrigin: "top center",
            transform: "scale(" + (window.innerHeight / 70) * 0.07 + ")"
          }}
        >
          {"Wordle Stars"}
        </div>
      </div>
    );
  }
}
