import React, { Component } from "react";
import BigButton from "./BigButton";
import Crypto from "./CryptoUtils";

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

const keyboard = {
  Q: { left: 0 * 60, top: 0, width: 50 },
  W: { left: 1 * 60, top: 0, width: 50 },
  E: { left: 2 * 60, top: 0, width: 50 },
  R: { left: 3 * 60, top: 0, width: 50 },
  T: { left: 4 * 60, top: 0, width: 50 },
  Y: { left: 5 * 60, top: 0, width: 50 },
  U: { left: 6 * 60, top: 0, width: 50 },
  I: { left: 7 * 60, top: 0, width: 50 },
  O: { left: 8 * 60, top: 0, width: 50 },
  P: { left: 9 * 60, top: 0, width: 50 },
  A: { left: 30 + 0 * 60, top: 80, width: 50 },
  S: { left: 30 + 1 * 60, top: 80, width: 50 },
  D: { left: 30 + 2 * 60, top: 80, width: 50 },
  F: { left: 30 + 3 * 60, top: 80, width: 50 },
  G: { left: 30 + 4 * 60, top: 80, width: 50 },
  H: { left: 30 + 5 * 60, top: 80, width: 50 },
  J: { left: 30 + 6 * 60, top: 80, width: 50 },
  K: { left: 30 + 7 * 60, top: 80, width: 50 },
  L: { left: 30 + 8 * 60, top: 80, width: 50 },
  ENTER: { left: -20, top: 160, width: 100 },
  Z: { left: -20 + 110 + 0 * 60, top: 160, width: 50 },
  X: { left: -20 + 110 + 1 * 60, top: 160, width: 50 },
  C: { left: -20 + 110 + 2 * 60, top: 160, width: 50 },
  V: { left: -20 + 110 + 3 * 60, top: 160, width: 50 },
  B: { left: -20 + 110 + 4 * 60, top: 160, width: 50 },
  N: { left: -20 + 110 + 5 * 60, top: 160, width: 50 },
  M: { left: -20 + 110 + 6 * 60, top: 160, width: 50 },
  DELETE: { left: -20 + 110 + 7 * 60, top: 160, width: 100 }
};

class Key extends Component {
  state = {
    button: ""
  };
  render() {
    return (
      <div
        style={{
          ...this.props.style,
          position: "absolute",
          height: 70,
          borderRadius: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: this.props.state == undefined ? "black" : "white",
          fontFamily: "PasseroOne-Regular, Passero One",
          fontSize: 30,
          cursor: "grab",
          userSelect: "none",
          filter:
            "brightness(" +
            (this.state.button == "pressed" || this.props.pressed
              ? 0.9
              : this.state.button == "hovered"
              ? 1.15
              : 1) +
            ")",
          backgroundColor:
            this.props.state == undefined
              ? "rgb(179, 179, 179)"
              : !this.props.state
              ? "rgb(101, 101, 101)"
              : "rgb(73, 163, 228)"
        }}
        onClick={this.props.onClick}
        onMouseEnter={() => {
          this.setState({ button: "hovered" });
        }}
        onMouseLeave={() => {
          this.setState({ button: "" });
        }}
        onMouseDown={() => {
          this.setState({ button: "pressed" });
        }}
        onMouseUp={() => {
          this.setState({ button: "hovered" });
        }}
      >
        {this.props.value}
      </div>
    );
  }
}

class Keyboard extends Component {
  state = {
    pressed: {}
  };
  componentDidMount() {
    window.addEventListener("keydown", event => {
      if (this.props.active != "solve") return;
      if (event.keyCode == 8 || event.keyCode == 46) {
        this.state.pressed["DELETE"] = true;
        this.setState({});
        this.props.onClick("DELETE");
      } else if (event.keyCode == 13) {
        this.state.pressed["ENTER"] = true;
        this.setState({});
        this.props.onClick("ENTER");
      } else if (event.keyCode >= 65 && event.keyCode <= 90) {
        this.state.pressed[letters[event.keyCode - 65]] = true;
        this.setState({});
        this.props.onClick(letters[event.keyCode - 65]);
      }
    });
    window.addEventListener("keyup", event => {
      if (this.props.active != "solve") return;
      if (event.keyCode == 8 || event.keyCode == 46) {
        this.state.pressed["DELETE"] = false;
        this.setState({});
      } else if (event.keyCode == 13) {
        this.state.pressed["ENTER"] = false;
        this.setState({});
      } else if (event.keyCode >= 65 && event.keyCode <= 90) {
        this.state.pressed[letters[event.keyCode - 65]] = false;
        this.setState({});
      }
    });
  }
  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          bottom: 20,
          width: "100%",
          height: 3 * 80 - 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transform: "scale(0.9)"
        }}
      >
        <div
          style={{
            position: "relative",
            width: 60 * 10 - 10,
            height: "100%"
            //backgroundColor: "rgb(186, 186, 186)"
          }}
        >
          {Object.keys(keyboard).map((key, id) => {
            return (
              <Key
                pressed={this.state.pressed[key]}
                key={key}
                value={key}
                state={this.props.letterStatus[key]}
                style={keyboard[key]}
                onClick={() => this.props.onClick(key)}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

class Letter extends Component {
  render() {
    return (
      <div
        style={{
          position: "relative",
          width: 65,
          height: 65,
          outline:
            this.props.state == undefined && !this.props.greyed
              ? "1px solid black"
              : undefined,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: this.props.state == undefined ? "black" : "white",
          backgroundColor: this.props.greyed
            ? "rgb(209, 208, 208)"
            : this.props.letter != undefined
            ? this.props.state == 2
              ? "rgb(79, 164, 58)"
              : this.props.state == 1
              ? "rgb(190, 147, 72)"
              : this.props.state == 0
              ? "rgb(129, 129, 129)"
              : "rgb(247, 247, 247)"
            : "rgb(237, 237, 237)",
          fontFamily: "PasseroOne-Regular, Passero One",
          fontSize: 35,
          transition:
            "transform ease-in-out 0.2s, background-color ease-in-out 0.2s",
          transform: "scale(" + (this.props.letter ? 1.03 : 1) + ")"
        }}
      >
        {this.props.letter}
      </div>
    );
  }
}

function CreateCells(maxGuesses, numLetters) {
  let cells = [];
  for (let i = 0; i < maxGuesses; i++) {
    let row = [];
    for (let j = 0; j < numLetters; j++) {
      row.push({});
    }
    cells.push(row);
  }
  return cells;
}

class GuessField extends Component {
  componentDidMount() {
    this.reset();
  }

  reset() {
    this.state.curGuessNum = 0;
    this.state.over = 0; // 1: winner, -1: lost, 0: game on
    this.state.cells = CreateCells(
      this.props.maxGuesses,
      this.props.numLetters
    );
    this.setState({});
  }

  deleteLetter() {
    if (this.state.over != 0) return;
    if (this.state.curGuessNum >= this.state.cells.length) return;
    let emptyCell = 0;
    for (
      emptyCell = 0;
      emptyCell < this.state.cells[this.state.curGuessNum].length;
      emptyCell++
    ) {
      if (
        this.state.cells[this.state.curGuessNum][emptyCell].letter == undefined
      ) {
        break;
      }
    }
    if (emptyCell > 0) {
      this.state.cells[this.state.curGuessNum][emptyCell - 1] = {};
      this.setState({});
    }
  }

  addLetter(letter) {
    if (this.state.over != 0) return;
    if (this.state.curGuessNum >= this.state.cells.length) return;
    let emptyCell = 0;
    for (
      emptyCell = 0;
      emptyCell < this.state.cells[this.state.curGuessNum].length;
      emptyCell++
    ) {
      if (
        this.state.cells[this.state.curGuessNum][emptyCell].letter == undefined
      ) {
        break;
      }
    }

    if (emptyCell < this.props.numLetters) {
      this.state.cells[this.state.curGuessNum][emptyCell] = { letter };
      this.setState({});
    }
  }

  onExternalClick(letter) {
    if (this.state.over != 0) return;
    if (letter == "ENTER") {
      this.enter();
    } else if (letter == "DELETE") {
      this.deleteLetter();
    } else {
      this.addLetter(letter);
    }
  }

  testWord(word) {
    if (this.state.over != 0) return;
    if (this.props.puzzleId == undefined) return;
    // 0= letter never present, 1: letter present but wrong place; 2: letter guessed
    let result = this.props.be.tryWord(word, this.props.puzzleId, result => {
      if (result.length != word.length) return;
      let solved = true;
      for (let i = 0; i < result.length; i++) {
        if (result[i] != 2) solved = false;
        this.state.cells[this.state.curGuessNum][i].state = result[i];
        this.props.onLetterStatus(word[i], result[i] != 0);
      }
      if (solved || this.state.curGuessNum + 1 >= this.state.cells.length) {
        this.setState({ over: solved ? 1 : -1 });
        this.props.onGameFinished();
      }
      this.setState({ curGuessNum: this.state.curGuessNum + 1 });
    });
  }

  enter() {
    if (this.state.over != 0) return;
    if (this.state.curGuessNum >= this.state.cells.length) return;
    if (
      this.state.cells[this.state.curGuessNum][
        this.state.cells[this.state.curGuessNum].length - 1
      ].letter == undefined
    )
      return;
    this.testWord(
      this.state.cells[this.state.curGuessNum].reduce((prev, cur) => {
        return prev + cur.letter;
      }, "")
    );
  }

  // this.props.numLetters
  // this.props.maxGuesses
  state = {
    curGuessNum: 0,
    cells: []
  };

  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 60,
          width: "100%",
          height: this.props.maxGuesses * 75 - 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transform: "scale(0.9)"
        }}
      >
        <div
          style={{
            position: "relative",
            width: this.props.numLetters * 75 - 5,
            height: "100%"
            //backgroundColor: "rgb(159, 159, 159)"
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: -75,
              width: "100%",
              height: 55,
              fontFamily: "DenkOne-Regular, Denk One",
              fontSize: 25,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color:
                this.props.puzzleId == undefined
                  ? "rgb(101, 101, 101)"
                  : "rgb(73, 31, 74)"
            }}
          >
            {this.props.puzzleId == undefined
              ? "New game is one click away."
              : this.state.over == 0
              ? "Game on!"
              : this.state.over == -1
              ? ".. Game over .."
              : "You solved it!"}
          </div>
          {this.props.puzzleId == undefined ||
          this.state.curGuessNum >= this.state.cells.length ||
          this.state.over != 0 ? (
            <div />
          ) : (
            <div
              style={{
                position: "absolute",
                width: this.props.numLetters * 75 + 6 + 40,
                height: 75,
                left: -8 - 20,
                top: 75 * this.state.curGuessNum - 5,
                borderRadius: 20,
                backgroundColor: "rgb(249, 226, 199)",
                outline: "1px solid rgb(113, 113, 113)",
                display: "flex",
                //justifyContent: "center"
                alignItems: "center",
                transition: "top ease-in-out 0.4s"
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: 8,
                  height: 8,
                  top: 33,
                  left: 10,
                  borderRadius: 5,
                  backgroundColor: "rgb(142, 142, 142)"
                }}
              />
              <div
                style={{
                  position: "absolute",
                  width: 8,
                  height: 8,
                  top: 33,
                  right: 10,
                  borderRadius: 5,
                  backgroundColor: "rgb(142, 142, 142)"
                }}
              />
            </div>
          )}
          {this.state.cells.map((row, id1) => {
            return (
              <div
                key={"row" + id1}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: 75,
                  left: 0,
                  top: 75 * id1
                }}
              >
                {row.map((cell, id2) => {
                  return (
                    <div
                      key={"columnn" + id2}
                      style={{
                        position: "absolute",
                        width: 75,
                        height: "100%",
                        left: 75 * id2,
                        top: 0
                      }}
                    >
                      <Letter
                        key={id2}
                        letter={cell.letter}
                        state={cell.state}
                        greyed={
                          this.state.over != 0 && id1 >= this.state.curGuessNum
                        }
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

class Stats extends Component {
  state = {
    stats: { wins: 50, losses: 50, average: 3.2 }
  };
  render() {
    return (
      <div
        style={{
          position: "absolute",
          right: 20,
          top: 0,
          height: arr.length * 65,
          width: 180,
          transformOrigin: "right center",
          transform:
            "translate(0px, " +
            (window.innerHeight / 2 - (arr.length * 65) / 2) +
            "px) scale(" +
            (window.innerHeight / (arr.length * 65 * 2)) * 0.96 +
            ")"
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            top: -20,
            width: "100%",
            height: 55,
            fontFamily: "DenkOne-Regular, Denk One",
            fontSize: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(89, 89, 89)"
          }}
        >
          {"STATS"}
        </div>
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 40,
            width: "100%",
            height: Object.keys(this.state.stats).length * 40 + 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgb(172, 172, 172)",
            borderRadius: 10
          }}
        >
          {Object.keys(this.state.stats).map((key, id) => {
            return (
              <div
                key={key}
                style={{
                  position: "absolute",
                  left: 0,
                  top: id * 40 + 10,
                  width: "100%",
                  height: 55,
                  borderRadius: 10,
                  fontFamily: "DenkOne-Regular, Denk One",
                  fontSize: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "rgb(238, 238, 238)"
                }}
              >
                {key + " : " + this.state.stats[key]}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const arr = [4, 5, 6, 7, 8, 9, 10];

class WordList extends Component {
  state = {
    button: {},
    selected: undefined
  };
  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: 20,
          top: -10,
          height: arr.length * 65,
          width: 180,
          transformOrigin: "left center",
          transform:
            "translate(0px, " +
            (window.innerHeight / 2 - (arr.length * 65) / 2) +
            "px) scale(" +
            (window.innerHeight / (arr.length * 65 * 2)) * 0.96 +
            ")"
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            top: -20,
            width: "100%",
            height: 55,
            fontFamily: "DenkOne-Regular, Denk One",
            fontSize: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(89, 89, 89)"
          }}
        >
          {"NEW GAME"}
        </div>
        {arr.map((key, id) => {
          return (
            <div
              key={key}
              style={{
                position: "absolute",
                left: 0,
                top: id * 65 + 40,
                width: "100%",
                height: 55,
                borderRadius: 10,
                fontFamily: "DenkOne-Regular, Denk One",
                fontSize: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                outline:
                  this.props.dict[key] == undefined
                    ? ""
                    : this.state.selected == key
                    ? "3px solid rgb(91, 152, 88)"
                    : "3px solid rgb(152, 115, 88)",
                color:
                  this.props.dict[key] == undefined
                    ? "rgb(224, 224, 224)"
                    : "rgb(31, 31, 31)",
                cursor: "grab",
                userSelect: "none",
                backgroundColor:
                  this.state.selected == key
                    ? "rgb(160, 237, 145)"
                    : this.props.dict[key] == undefined
                    ? "rgb(172, 172, 172)"
                    : this.state.button[key] == "hovered"
                    ? "rgb(244, 228, 181)"
                    : "rgb(227, 196, 144)"
              }}
              onMouseEnter={() => {
                this.state.button[key] = "hovered";
                this.setState({});
              }}
              onMouseLeave={() => {
                this.state.button[key] = "";
                this.setState({});
              }}
              onClick={() => {
                if (this.props.dict[key] == undefined) return;
                this.setState({ selected: key });
                this.props.onClick(key);
              }}
            >
              {key +
                " letters : " +
                (this.props.dict[key] == undefined
                  ? 0
                  : Object.keys(this.props.dict[key]).length)}
            </div>
          );
        })}
      </div>
    );
  }
}

export default class Solve extends Component {
  componentDidMount() {
    this.periodicPuzzleUpdate();
  }

  periodicPuzzleUpdate() {
    this.updatePuzzleList();
    this.updateTimeout = setTimeout(() => this.periodicPuzzleUpdate(), 60000);
  }

  updatePuzzleList() {
    this.props.be.listPuzzles(puzzles => {
      let dict = {};
      for (let puzzle of puzzles) {
        if (dict[puzzle.word_length] == undefined) {
          dict[puzzle.word_length] = {};
        }
        dict[puzzle.word_length][puzzle.id] = true;
      }
      // TODO: handle race conditions
      this.setState({ puzzles: dict });
    });
  }

  selectPuzzle(numLetters) {
    // select random puzzle from the list for now
    let available = this.state.puzzles[numLetters];
    if (available == undefined || Object.keys(available).length == 0) return;
    let puzzleId = Object.keys(available)[
      Math.floor(Math.random() * Object.keys(available).length)
    ];
    this.setState({ puzzleId, over: false });
  }

  state = {
    puzzleId: undefined,
    puzzles: {},
    numLetters: 5,
    letterStatus: {},
    quitPuzzlePopUp: undefined,
    over: false
  };

  onGameFinished() {
    this.setState({ over: true });
  }

  constructor(props) {
    super(props);
    this.guessRef = React.createRef();
  }

  onSelected(numLetters) {
    if (this.state.puzzleId == undefined || this.state.over) {
      this.resetPuzzle(numLetters);
      return;
    }
    this.setState({ quitPuzzlePopUp: numLetters });
  }

  resetPuzzle(numLetters) {
    this.selectPuzzle(numLetters);
    this.setState({ numLetters: numLetters, letterStatus: {} });
    // give a bit time for state to propagate
    setTimeout(() => this.guessRef.current.reset(), 10);
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "100%",
          width: "100%"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 20,
            left: 20,
            height: 70,
            width: 200,
            transformOrigin: "left top",
            transform: "scale(" + (window.innerHeight / 70) * 0.07 + ")"
          }}
        >
          <BigButton text="BACK" onClick={this.props.onLanding} />
        </div>
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            //outline: "5px solid rgb(247, 232, 197)",
            height: 760,
            width: 620,
            //backgroundColor: "blue",
            transform:
              "translate(" +
              (window.innerWidth / 2 - 620 / 2) +
              "px, " +
              (window.innerHeight / 2 - 760 / 2) +
              "px) scale(" +
              (window.innerHeight / 760) * 0.96 +
              ")"
          }}
        >
          <Keyboard
            active={this.props.active}
            letterStatus={this.state.letterStatus}
            onClick={letter => {
              this.guessRef.current.onExternalClick(letter);
            }}
          />
          <GuessField
            onGameFinished={this.onGameFinished.bind(this)}
            puzzleId={this.state.puzzleId}
            be={this.props.be}
            ref={this.guessRef}
            numLetters={this.state.numLetters}
            maxGuesses={6}
            onLetterStatus={(letter, status) => {
              this.state.letterStatus[letter] = status;
              this.setState({});
            }}
          />
        </div>
        <WordList
          dict={this.state.puzzles}
          onClick={this.onSelected.bind(this)}
        />
        <Stats />
        {this.state.quitPuzzlePopUp == undefined ? (
          <div />
        ) : (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "rgba(232, 230, 230, 0.76)",
              alignItems: "center"
            }}
          >
            <div
              style={{
                position: "relative",
                height: 200,
                width: 400,
                borderRadius: 10,
                outline: "3px solid rgb(203, 136, 82)",
                backgroundColor: "rgb(224, 211, 193)",
                transform: "scale(" + this.props.windowScale + ")"
              }}
              onClick={() => {
                this.setState({ quitPuzzlePopUp: undefined });
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 15,
                  left: "10%",
                  height: 100,
                  width: "80%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Slackey-Regular, Slackey",
                  fontSize: 20,
                  textAlign: "center"
                }}
              >
                {"Are you sure you would like to abandon current puzzle?"}
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 20,
                  right: 20,
                  height: 50,
                  width: 100,
                  borderRadius: 7,
                  outline: "3px solid rgb(106, 106, 106)",
                  backgroundColor:
                    this.state.yesbutton == "pressed"
                      ? "rgb(132, 219, 114)"
                      : this.state.yesbutton == "hovered"
                      ? "rgb(147, 247, 154)"
                      : "rgb(117, 227, 151)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Slackey-Regular, Slackey",
                  fontSize: 20,
                  cursor: "grab",
                  userSelect: "none"
                }}
                onClick={() => {
                  this.resetPuzzle(this.state.quitPuzzlePopUp);
                  this.setState({ quitPuzzlePopUp: undefined });
                }}
                onMouseEnter={() => {
                  this.setState({ yesbutton: "hovered" });
                }}
                onMouseLeave={() => {
                  this.setState({ yesbutton: "" });
                }}
                onMouseDown={() => {
                  this.setState({ yesbutton: "pressed" });
                }}
                onMouseUp={() => {
                  this.setState({ yesbutton: "hovered" });
                }}
              >
                {"YES"}
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 20,
                  left: 20,
                  height: 50,
                  width: 100,
                  borderRadius: 7,
                  outline: "3px solid rgb(106, 106, 106)",
                  backgroundColor:
                    this.state.nobutton == "pressed"
                      ? "rgb(219, 124, 114)"
                      : this.state.nobutton == "hovered"
                      ? "rgb(255, 145, 145)"
                      : "rgb(231, 108, 108)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Slackey-Regular, Slackey",
                  fontSize: 20,
                  cursor: "grab",
                  userSelect: "none"
                }}
                onMouseEnter={() => {
                  this.setState({ nobutton: "hovered" });
                }}
                onMouseLeave={() => {
                  this.setState({ nobutton: "" });
                }}
                onMouseDown={() => {
                  this.setState({ nobutton: "pressed" });
                }}
                onMouseUp={() => {
                  this.setState({ nobutton: "hovered" });
                }}
                onClick={() => {
                  this.setState({ quitPuzzlePopUp: undefined });
                }}
              >
                {"NO"}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
